import type {
  GetServerSideProps,
  GetServerSidePropsContext,
  NextPage,
} from "next";

import { HomeV2 } from "@components/Layout/Sites/HomeV2";

import { getServerSideBanner } from "@lib/api/layout";
import { useAuth } from "@contexts/AuthContext";
import { useLayoutStore } from "@store/layoutStore";
import { shallow } from "zustand/shallow";

type HomeProps = {
  adsBanner?: MainCardType[];
};

const Home: NextPage<HomeProps> = ({ adsBanner }) => {
  const { isLogged } = useAuth();
  const { showSearchLogoutHomev2 } = useLayoutStore(
    (state) => ({
      showSearchLogoutHomev2: state.externalConfig?.showSearchLogoutHomev2,
    }),
    shallow
  );

  return (
    <div className=" tw-font-cabin">
      <HomeV2
        adsBanner={adsBanner}
        isLogged={isLogged || !!showSearchLogoutHomev2}
        sectionsAds={[
          {
            name: "hotels",
            space: "space1",
            title: "Hotel deals of popular destinations",
            subtitle:
              "Amazing hotel deals in top destinations around the world",
          },
        ]}
      />
    </div>
  );
};

export default Home;
export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  const data = await getServerSideBanner(context, "/", "hotels");

  return {
    props: {
      adsBanner: data.adsBanner || [],
    },
  };
};
